import { createRouter, createWebHistory } from "vue-router";
import { useStore } from "@/stores";

import mmwMusicStoreCategories from "@/mmw_music_store_categories.json";
import mmwSections from "@/mmw_sections.json";

const mmwMusicStoreCategoriesRegEx = Object.keys(mmwMusicStoreCategories).join(
  "|",
);
const mmwSectionsRegEx = Object.keys(mmwSections).join("|");

export const parentsAreaRoutes = [
  "cancel_subscription",
  "charge_history",
  "contact",
  "customer_support",
  "email_change",
  "logout",
  "my_account",
  "name_change",
  "parent_help",
  "help",
  "parents_area",
  "admin_area",
  "pause_or_resume_subscription",
  "payment_method_change",
  "password_change",
  "plan_change",
  "progress",
  "resubscribe",
  "student_add",
  "student_delete",
];

const scrollPositions = Object(null);

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("../views/HomeView.vue"),
      meta: { activeStudentRequired: true },
    },
    {
      path: "/signup",
      name: "signup",
      component: () => import("../views/users/SignUpView.vue"),
      meta: { allowAnonymous: true },
    },
    {
      path: "/email-verify/:token",
      name: "email_verification",
      component: () => import("../views/users/VerifyEmailView.vue"),
      props: true,
    },
    {
      path: "/onboard",
      name: "onboard",
      component: () => import("../views/users/OnboardView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/onboard-school",
      name: "onboard_school",
      component: () => import("../views/users/OnboardSchoolView.vue"),
      meta: { schoolUsersOnly: true },
    },
    {
      path: "/questionnaire/:studentId?",
      name: "questionnaire",
      component: () => import("../views/users/QuestionnaireView.vue"),
      props(route) {
        const props = { ...route.params };

        if (props.studentId === undefined || props.studentId === "") {
          // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
          props.studentId = Infinity;
        } else {
          // @ts-expect-error Type 'number' is not assignable to type 'string | string[]'.
          props.studentId = +props.studentId;
        }

        return props;
      },
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../views/users/LoginView.vue"),
      meta: { allowAnonymous: true },
    },
    {
      path: "/password-reset",
      name: "password_reset",
      component: () => import("../views/users/PasswordResetView.vue"),
      meta: { allowAnonymous: true },
    },
    {
      path: "/password-reset/:uidb64/:token",
      name: "password_reset_confirm",
      component: () => import("../views/users/PasswordResetConfirmView.vue"),
      meta: { allowAnonymous: true },
      props: true,
    },
    {
      path: "/parents-area",
      name: "parents_area",
      component: () => import("../views/parents/ParentsIndexView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/admin-area",
      name: "admin_area",
      component: () => import("../views/parents/ParentsIndexView.vue"),
      meta: { schoolUsersOnly: true },
    },
    {
      path: "/my-account",
      name: "my_account",
      component: () => import("../views/parents/MyAccountView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("../views/parents/ContactView.vue"),
    },
    {
      path: "/charge-history",
      name: "charge_history",
      component: () => import("../views/parents/ChargeHistoryView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/cancel-subscription",
      name: "cancel_subscription",
      component: () => import("../views/parents/SubscriptionCancelView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/resubscribe",
      name: "resubscribe",
      component: () => import("../views/parents/ResubscribeView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/pause-or-resume-subscription",
      name: "pause_or_resume_subscription",
      component: () =>
        import("../views/parents/SubscriptionPauseOrResumeView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/payment-method-change",
      name: "payment_method_change",
      component: () => import("../views/parents/PaymentMethodChangeView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/plan-change",
      name: "plan_change",
      component: () => import("../views/parents/PlanChangeView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/email-change",
      name: "email_change",
      component: () => import("../views/parents/EmailChangeView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/name-change",
      name: "name_change",
      component: () => import("../views/parents/NameChangeView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/password-change",
      name: "password_change",
      component: () => import("../views/parents/PasswordChangeView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/logout",
      name: "logout",
      component: () => import("../views/parents/LogoutView.vue"),
    },
    {
      path: "/add-student",
      name: "student_add",
      component: () => import("../views/parents/StudentAddView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/delete-student",
      name: "student_delete",
      component: () => import("../views/parents/StudentDeleteView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/progress",
      name: "progress",
      component: () => import("../views/parents/ProgressView.vue"),
    },
    {
      path: "/parent-help",
      name: "parent_help",
      component: () => import("../views/parents/ParentHelpView.vue"),
      meta: { regularUsersOnly: true },
    },
    {
      path: "/help",
      name: "help",
      component: () => import("../views/parents/ParentHelpView.vue"),
      meta: { schoolUsersOnly: true },
    },
    {
      path: "/customer-support",
      name: "customer_support",
      component: () => import("../views/parents/CustomerSupportView.vue"),
    },
    {
      path: "/choose-student",
      name: "choose_student",
      component: () => import("../views/ChooseStudentView.vue"),
    },
    {
      path: `/:sectionSlug(${mmwSectionsRegEx})`,
      name: "lessons",
      component: () => import("../views/lessons/LessonIndexView.vue"),
      meta: { activeStudentRequired: true },
      props: true,
    },
    {
      path: `/:sectionSlug(${mmwSectionsRegEx})/worksheets`,
      name: "worksheets",
      component: () => import("../views/WorksheetIndexView.vue"),
      meta: { activeStudentRequired: true },
      props: true,
    },
    {
      path: `/:sectionSlug(${mmwSectionsRegEx})/quizzes`,
      name: "quizzes",
      component: () => import("../views/QuizIndexView.vue"),
      meta: { activeStudentRequired: true },
      props: true,
    },
    {
      path: `/:sectionSlug(${mmwSectionsRegEx})/jam-alongs`,
      name: "jam_alongs",
      component: () => import("../views/JamAlongIndexView.vue"),
      meta: { activeStudentRequired: true },
      props: true,
    },
    {
      path: "/music-games",
      name: "music_games",
      component: () => import("../views/music_games/MusicGamesIndexView.vue"),
      meta: { activeStudentRequired: true },
    },
    {
      path: "/music-games/musical-memory-match",
      name: "music_game_musical_memory_match",
      component: () =>
        import("../views/music_games/MusicGameMusicalMemoryMatchView.vue"),
      meta: { activeStudentRequired: true },
    },
    {
      path: "/music-games/music-studio-jr",
      name: "music_game_music_studio_jr",
      component: () =>
        import("../views/music_games/MusicGameMusicStudioJrView.vue"),
      meta: { activeStudentRequired: true },
    },
    {
      path: "/music-games/composers-corner",
      name: "music_game_composers_corner",
      component: () =>
        import("../views/music_games/MusicGameComposersCornerView.vue"),
      meta: { activeStudentRequired: true },
    },
    {
      path: "/music-store",
      name: "music_store",
      component: () => import("../views/music_store/MusicStoreIndexView.vue"),
      meta: { activeStudentRequired: true },
    },
    {
      path: "/music-store/collection",
      name: "music_store_collection",
      component: () =>
        import("../views/music_store/MusicStoreCollectionView.vue"),
      meta: { activeStudentRequired: true },
    },
    {
      path: `/music-store/:categorySlug(${mmwMusicStoreCategoriesRegEx})`,
      name: "music_store_category",
      component: () =>
        import("../views/music_store/MusicStoreCategoryView.vue"),
      meta: { activeStudentRequired: true },
      props: true,
    },
    {
      path: `/music-store/:categorySlug(${mmwMusicStoreCategoriesRegEx})/:instrumentSlug`,
      name: "music_store_instrument",
      component: () =>
        import("../views/music_store/MusicStoreInstrumentView.vue"),
      meta: { activeStudentRequired: true },
      props: true,
    },
    {
      path: "/my-music-room/:roomSlug?",
      name: "my_music_room",
      component: () => import("../views/music_rooms/MyMusicRoomView.vue"),
      meta: { activeStudentRequired: true },
      props: true,
    },
    {
      path: "/my-rooms",
      name: "my_rooms",
      component: () => import("../views/music_rooms/MyRoomsView.vue"),
      meta: { activeStudentRequired: true },
    },
    {
      path: "/my-musician",
      name: "my_musician",
      component: () => import("../views/MyMusicianView.vue"),
      meta: { activeStudentRequired: true },
    },
    {
      path: "/:catchAll(.*)",
      name: "404",
      component: () => import("../views/PageNotFoundView.vue"),
      meta: { allowAnonymous: true },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    let elementToScroll: HTMLElement;

    if (parentsAreaRoutes.includes(String(to.name))) {
      elementToScroll = document.querySelector("main") as HTMLElement;
    } else {
      elementToScroll = document.documentElement;
    }

    if (savedPosition && to.path && scrollPositions[to.path] !== undefined) {
      elementToScroll.scrollTop = scrollPositions[to.path];
    } else {
      elementToScroll.scrollTop = 0;
    }
  },
});

router.beforeEach(async (to, from, next) => {
  const store = useStore();

  store.latestFromRoute = from;
  const appNotLaunched = document.querySelector(".app-not-launched-content");

  if (appNotLaunched) {
    await new Promise<void>((resolve) => {
      const observer = new MutationObserver(() => {
        observer.disconnect();
        resolve();
      });

      observer.observe(appNotLaunched, { attributes: true });
    });
  }

  if (
    to.matched.some((record) => record.meta.allowAnonymous) ||
    store.loggedIn
  ) {
    if (from.path) {
      if (parentsAreaRoutes.includes(String(from.name))) {
        scrollPositions[from.path] = document.querySelector("main")?.scrollTop;
      } else if (from.name === "music_store_category") {
        store.musicStoreCategoryScrollData = {
          scrollPosition:
            document.querySelector(".music-store-instruments")?.scrollTop || 0,
          toPath: to.path,
        };
      } else {
        scrollPositions[from.path] = document.documentElement.scrollTop;
      }
    }

    if (store.userData.needs_onboarding) {
      if (store.userData.user_type === "regular") {
        if (
          ["signup", "email_verification", "onboard"].includes(String(to.name))
        ) {
          next();
        } else {
          next({ name: "onboard" });
        }
      } else if (to.name === "onboard_school") {
        next();
      } else {
        next({ name: "onboard_school" });
      }
    } else if (
      to.matched.some((record) => record.meta.activeStudentRequired) &&
      store.activeStudent === null
    ) {
      next({
        name: "choose_student",
        query: { next: to.fullPath },
      });
    } else if (
      (to.matched.some((record) => record.meta.regularUsersOnly) &&
        store.userData.user_type !== "regular") ||
      (to.matched.some((record) => record.meta.schoolUsersOnly) &&
        store.userData.user_type === "regular") ||
      (["onboard", "onboard_school"].includes(String(to.name)) &&
        store.userData.needs_onboarding === undefined)
    ) {
      next({ name: "home" });
    } else {
      next();
    }
  } else {
    next({
      name: "login",
      query: { next: to.fullPath },
    });
  }
});

router.afterEach(async () => {
  const store = useStore();
  store.firstRouteReached = true;
});

router.onError((error) => {
  if (
    /Failed to fetch dynamically imported module:/.test(error.message) ||
    /Unable to preload CSS for \/assets\//.test(error.message)
  ) {
    const store = useStore();
    store.showReloadMessage = true;
  }
});

export default router;
