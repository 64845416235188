export default function scrollToFirstError(
  element: HTMLElement | undefined = undefined,
  querySelector = ".js-form-error",
): void {
  let elementToScroll: HTMLElement | Window;

  if (element) {
    elementToScroll = element;
  } else {
    element = document.documentElement;
    elementToScroll = window;
  }

  const firstError = element.querySelector(querySelector);

  if (firstError) {
    const domRect = firstError.getBoundingClientRect();

    elementToScroll.scrollTo({
      top: domRect.top + element.scrollTop - 70,
      left: domRect.left + element.scrollLeft,
      behavior: "smooth",
    });
  }
}
