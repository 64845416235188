import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";
import { type RouteRecordNormalized } from "vue-router";
import { type StudentInterface, type UserDataInterface } from "@/types/users";

const userDataDefault: UserDataInterface = {
  id: 0,
  user_type: "regular",
  email: "john_doe@example.com",
  first_name: "John",
  last_name: "Doe",
  has_visited_stage: false,
};

export const useStore = defineStore("main", {
  state: () => ({
    latestFromRoute: { matched: [] as RouteRecordNormalized[] },
    firstRouteReached: false,
    loggedIn: false,
    userData: structuredClone(userDataDefault),
    activeStudent: null as StudentInterface | null,
    howToUseVideoIds: {} as Record<string, number>,
    signupEmailSubscribeCheckedByDefault: false,
    desktopPaddingVerticalAmount: "0px",
    scrollbarSize: 0,
    showReloadMessage: false,
    musicStoreCategoryScrollData: {
      scrollPosition: 0,
      toPath: "",
    },
  }),
  actions: {
    logIn(userData: UserDataInterface) {
      this.userData = userData;
      Sentry.setUser({ email: userData.email });

      if ("profitwell" in window) {
        // @ts-expect-error 'window.profitwell' is of type 'unknown'.
        window.profitwell("start", { user_email: userData.email });
      }

      this.loggedIn = true;
    },
    logOut() {
      this.loggedIn = false;
      this.userData = structuredClone(userDataDefault);
      this.activeStudent = null;
      Sentry.configureScope((scope) => scope.setUser(null));
    },
  },
});
