<script setup lang="ts">
import { onMounted, ref } from "vue";
import BaseButton from "@/components/BaseButton.vue";

const props = defineProps({
  noAutoShow: {
    type: Boolean,
    required: false,
    default: false,
  },
  closeLabel: {
    type: String,
    required: false,
    default: "Close",
  },
  closeGuard: {
    type: Function,
    required: false,
    default: undefined,
  },
});

const emit = defineEmits(["modalClosing", "modalClosed"]);

const modalRef = ref<HTMLDialogElement | null>(null);

const modalShowing = ref(false);

const waitingForCloseGuard = ref(false);

function closeModal2(e: TransitionEvent) {
  if (e.target === modalRef.value) {
    modalRef.value?.removeEventListener("transitionend", closeModal2);
    modalRef.value?.close();
    emit("modalClosed");
  }
}

async function closeModal() {
  if (props.closeGuard) {
    if (waitingForCloseGuard.value) {
      return false;
    }

    waitingForCloseGuard.value = true;
    const proceed = await props.closeGuard();
    waitingForCloseGuard.value = false;

    if (!proceed) {
      return false;
    }
  }

  modalRef.value?.addEventListener("transitionend", closeModal2);
  modalShowing.value = false;
  emit("modalClosing");
}

function cancelHandler(e: Event) {
  e.preventDefault();
  closeModal();
}

function backdropClicked(e: MouseEvent) {
  if (e.target === modalRef.value) {
    closeModal();
  }
}

function showModal() {
  if (modalShowing.value) {
    return;
  }

  modalRef.value?.showModal();

  setTimeout(() => {
    modalShowing.value = true;
  }, 0);
}

onMounted(() => {
  if (!props.noAutoShow) {
    showModal();
  }
});

defineExpose({ showModal, closeModal, modalShowing });
</script>

<template>
  <dialog
    ref="modalRef"
    class="modal"
    :class="{ 'modal-transition': modalShowing }"
    :inert="!modalShowing"
    @click="backdropClicked"
    @cancel="cancelHandler"
  >
    <div>
      <div class="modal-header">
        <BaseButton
          type="button"
          btn-style="transparent"
          class="modal-close"
          :aria-label="closeLabel"
          @click="closeModal"
        >
          <FontAwesomeIcon icon="fa-solid fa-xmark" />
        </BaseButton>

        <slot name="header" />
      </div>

      <div class="modal-body">
        <slot />
      </div>
    </div>
  </dialog>
</template>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  margin: 0;
  padding: 0;
  min-width: 280px;
  max-width: 95vw;
  max-height: 80vh;
  max-height: 95dvh;
  color: inherit;
  border: 0;
  border-radius: 5px;
  overflow-y: auto;
  overscroll-behavior-y: contain;
  transform: translate(-50%, 100vh);

  &[open].modal-transition {
    transform: translate(-50%, -50%);
  }

  > div {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.modal,
.modal[open]::backdrop {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s linear 0.2s,
    opacity 0.2s linear,
    transform 0.2s linear;
}

.modal[open].modal-transition,
.modal[open].modal-transition::backdrop {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.modal[open]::backdrop {
  background-color: rgba(0, 0, 0, 0.8);
  overscroll-behavior-y: contain;
}

.modal-header {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.75rem 2rem;
  height: 60px;
  background-color: #fff;
  z-index: 1;

  :deep(h2),
  :deep(p) {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  :deep(h2) {
    margin: 0;
  }

  :deep(p:after) {
    display: none;
  }
}

.modal-close {
  position: absolute;
  top: 50%;
  right: 14px;
  font-size: 18px;
  transform: translateY(-50%);
}

.modal-body {
  flex: 1;
  height: 1px;
  padding: 0.25rem 1.6rem 1.55rem;
}

// Safari
@supports (font: -apple-system-body) {
  .modal {
    height: 80vh;
  }
}
</style>
